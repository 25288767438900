import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img
            src="/images/bullzimage/Bullzgames_logo.png"
            alt="Bullz Games Logo"
            className="logo-horizontal"
          />
          <img
            src="/images/bullzimage/bullzvertical.png"
            alt="Bullz Games Vertical Logo"
            className="logo-vertical"
          />
        </Link>
      </div>
      <div className="navbar-buttons">
        <button className="btn login-btn">
          <a href="https://app.bullz.games" className="btn-link">
            <img
              src="/images/bullzimage/web3white.png"
              alt="Web3Auth Logo"
              className="login-icon"
            />
            CONNECT
          </a>
        </button>
        <button className="btn signup-btn">
          <a href="https://app.bullz.games">SIGN UP</a>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
