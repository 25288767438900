import { decrypt } from './symmetricEncryption'; // Assure-toi que ce chemin est correct

const connectToWebSocket = (onDataReceived) => {
  const casinoId = process.env.REACT_APP_CASINO_ID;
  const wss = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}?casinoId=${encodeURIComponent(casinoId)}`);

  wss.onopen = () => {
    console.log('Connected to WebSocket');
  };

  wss.onmessage = (message) => {
    try {
      const encryptedData = JSON.parse(message.data); // Message encrypté
      const decryptedMessage = decrypt(encryptedData); // Déchiffrement
      // console.log(decryptedMessage)
      const data = JSON.parse(decryptedMessage);

      // Passe toutes les données reçues à React pour traitement
      onDataReceived(data);
    } catch (error) {
      console.error('Error processing message:', error);
    }
  };

  wss.onclose = () => {
    console.log('WebSocket connection closed. Reconnecting...');
    setTimeout(() => connectToWebSocket(onDataReceived), 5000); // Reconnexion après 5 sec
  };

  wss.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  return wss;
};

export default connectToWebSocket;
