import React from "react";
import "./PrivacyPolicy.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Last updated:</strong> 14 June 2022
        </p>
        <p>
          This Privacy Policy explains the policies and procedures of
          <strong> Bullz.games</strong> regarding the collection, use, and
          disclosure of your information when you use our Service. It also
          explains your privacy rights and how the law protects you.
        </p>
        <p>
          We use your personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy. <strong>Bullz.games</strong> is
          operated by <strong>Bullz Gaming N.V.</strong>, a company registered
          under the laws of Curaçao with its registered office at Brionplein 4,
          Willemstad, Curaçao.
        </p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          Words with initial capital letters have meanings defined under the
          following conditions. These definitions apply regardless of whether
          the terms appear in singular or plural form.
        </p>
        <h3>Definitions</h3>
        <ul>
          <li>
            <strong>You:</strong> Refers to the individual accessing or using
            the Service.
          </li>
          <li>
            <strong>Company:</strong> Refers to Bullz Gaming N.V.
          </li>
          <li>
            <strong>Website:</strong> Refers to bullz.games, accessible at{" "}
            <a href="https://www.bullz.games">www.bullz.games</a>.
          </li>
          <li>
            <strong>Service:</strong> Refers to the Website.
          </li>
          <li>
            <strong>Country:</strong> Refers to Curaçao.
          </li>
          <li>
            <strong>Personal Data:</strong> Refers to information relating to an
            identified or identifiable individual, such as names, identification
            numbers, or online identifiers.
          </li>
          <li>
            <strong>Cookies:</strong> Small files stored on your device to
            enhance your experience.
          </li>
          <li>
            <strong>Data Controller:</strong> The entity that determines the
            purposes of processing personal data.
          </li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>We may collect the following personally identifiable information:</p>
        <ul>
          <li>Email address</li>
          <li>First and last name</li>
          <li>Phone number</li>
          <li>Address, State, ZIP/Postal code, City</li>
          <li>Bank account information for transactions</li>
          <li>Date of birth or identification documents for verification</li>
        </ul>

        <h4>Usage Data</h4>
        <p>
          Usage data includes information such as your device's IP address,
          browser type, and pages visited.
        </p>

        <h2>Tracking Technologies and Cookies</h2>
        <p>
          We use cookies and similar technologies to track user activity. You
          can manage your cookie preferences in your browser settings.
        </p>

        <h2>Your Rights Under GDPR</h2>
        <p>
          If you are located in the EU, you have rights to access, update, or
          delete your data, object to processing, and withdraw consent. Contact
          us at <a href="mailto:contact@bullz.games">contact@bullz.games</a> to
          exercise these rights.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have questions, contact us via email at
          <a href="mailto:contact@bullz.games">contact@bullz.games</a> or by
          phone at +599 9 1234567 (09:00 - 16:00 UTC).
        </p>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
