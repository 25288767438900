const cryptoConfig = {
    BTCUSDT: { precision: 2, id: 1 },
    ETHUSDT: { precision: 3, id: 1027 },
    ORDIUSDT: { precision: 4, id: 25028 },
    SOLUSDT: { precision: 4, id: 16116 },
    PEPEUSDT: { precision: 8, id: 24478 },
    DOGEUSDT: { precision: 7, id: 74 },
    WIFUSDT: { precision: 6, id: 28752 },
    BNBUSDT: { precision: 3, id: 1839 },
    DOTUSDT: { precision: 5, id: 6636 },
    WLDUSDT: { precision: 5, id: 13502 },
    SEIUSDT: { precision: 6, id: 23149 },
    LINKUSDT: { precision: 5, id: 1975 },
    ARBUSDT: { precision: 6, id: 11841 },
    ADAUSDT: { precision: 6, id: 2010 },
    JUPUSDT: { precision: 6, id: 29210 },
    TIAUSDT: { precision: 5, id: 22861 },
    INJUSDT: { precision: 4, id: 7226 },
    PYTHUSDT: { precision: 6, id: 28177 },
    LTCUSDT: { precision: 4, id: 2 },
    BLURUSDT: { precision: 6, id: 23121 },
    APEUSDT: { precision: 6, id: 18876 },
    FTMUSDT: { precision: 6, id: 3513 },
    MATICUSDT: { precision: 6, id: 3890 },
    AVAXUSDT: { precision: 4, id: 5805 },
    BCHUSDT: { precision: 3, id: 1831 },
    TRXUSDT: { precision: 7, id: 1958 },
    MEMEUSDT: { precision: 7, id: 28301 },
    APTUSDT: { precision: 5, id: 21794 },
};

export default cryptoConfig;

