import React, { useEffect, useRef, useState } from "react";
import connectToWebSocket from "../components/connectToWebSocket";
import cryptoConfig from "../cryptoConfig";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import Framer Motion
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import Navbar from "../components/Navbar/Navbar";
import { FaBitcoin } from "react-icons/fa";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuDollarSign } from "react-icons/lu";
import { FaPlay } from "react-icons/fa";
import Footer from "../components/Footer/Footer";

const Homepage = () => {
  const [cryptoData, setCryptoData] = useState([]); // État pour stocker les coins reçus
  const [latestBets, setLatestBets] = useState([]);
  const [cryptoInfo, setCryptoInfo] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [valueDirections, setValueDirections] = useState({});
  const [volatilities, setVolatilities] = useState({});
  const [historicalData, setHistoricalData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const slidesContainerRef = useRef(null);
  const sortBets = (bets) => {
    return bets.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  };

  useEffect(() => {
    if (cryptoData.data) {
      setHistoricalData((prev) => {
        const updated = { ...prev };
        for (const coinSymbol in cryptoData.data) {
          if (!updated[coinSymbol]) {
            updated[coinSymbol] = [];
          }
          updated[coinSymbol] = [
            ...updated[coinSymbol],
            cryptoData.data[coinSymbol],
          ].slice(-500);
        }
        return updated;
      });
    }
  }, [cryptoData]);

  useEffect(() => {
    const wss = connectToWebSocket((data) => {
      if (data.type === "cryptoData") {
        setCryptoData(data.data);
      } else if (data.type === "initial") {
        setLatestBets(data.data);
        if (data.info) {
          setCryptoInfo(data.info); // Ajout des informations supplémentaires
        }
      } else if (data.type === "ActiveCoins") {
        setCryptoInfo(data.data);
      } else if (data.type === "cashedOutBet") {
        setLatestBets((prevBets) => {
          const updatedBets = sortBets([
            data.data,
            ...prevBets.filter((bet) => bet._id !== data.data._id),
          ]).slice(0, 15);
          return updatedBets;
        });
      }
    });

    return () => {
      wss.close();
    };
  }, []);

  // Fetch initial crypto info
  useEffect(() => {
    const getCryptoInfo = async () => {
      if (Array.isArray(cryptoInfo)) {
        setCryptoList(
          cryptoInfo.map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else if (typeof cryptoInfo === "object") {
        setCryptoList(
          Object.values(cryptoInfo).map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else {
        console.error("Unexpected data format:", cryptoInfo);
      }
    };

    getCryptoInfo();
  }, [cryptoInfo]);

  // Update cryptoList with prices from cryptoData when cryptoData changes
  useEffect(() => {
    if (cryptoData.data) {
      setCryptoList((prevCryptoList) =>
        prevCryptoList.map((crypto) => {
          const cryptoName = crypto.symbol + "USDT";
          const newPrice = cryptoData.data[cryptoName] || null;

          return {
            ...crypto,
            price: newPrice,
            previousPrice: crypto.price,
            cryptoName: cryptoName,
          };
        })
      );
    }
  }, [cryptoData]);

  const getAnimationProps = () => {
    return {
      initial: { opacity: 0, y: -70 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 70 },
      transition: { duration: 0.5 },
    };
  };

  useEffect(() => {
    if (cryptoList.length > 0) {
      const updatedDirections = {};
      cryptoList.forEach((crypto) => {
        if (crypto.price !== null && crypto.previousPrice !== null) {
          if (crypto.price > crypto.previousPrice) {
            updatedDirections[crypto.cryptoName] = "profit";
          } else if (crypto.price < crypto.previousPrice) {
            updatedDirections[crypto.cryptoName] = "loss";
          }
        }
      });
      setValueDirections((prevDirections) => ({
        ...prevDirections,
        ...updatedDirections,
      }));
    }
  }, [cryptoList]);

  const getFormattedValue = (value, symbol) => {
    if (value !== null) {
      const precision = cryptoConfig[symbol]?.precision || 2;

      const colorClass = valueDirections[symbol];

      const formattedValue = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(value);

      return (
        <span className={`asset-value ${colorClass}`}>{formattedValue}</span>
      );
    }

    return <span className="asset-value">-</span>;
  };

  const calculateVolatility = (prices) => {
    if (prices.length < 2)
      return {
        text: "Very Low Volatility",
        width: "0%",
        background: "#1a1d29",
      };

    const returns = prices.slice(1).map((p, i) => (p - prices[i]) / prices[i]);
    const mean = returns.reduce((a, b) => a + b, 0) / returns.length;
    const squareDiffs = returns.map((val) => Math.pow(val - mean, 2));
    const avgSquareDiff =
      squareDiffs.reduce((a, b) => a + b, 0) / squareDiffs.length;
    const volatility = Math.sqrt(avgSquareDiff) * Math.sqrt(60);

    const maxVolatilityForBar = 0.02;
    const volatilityPercentage = Math.min(
      (volatility / maxVolatilityForBar) * 1000,
      100
    );

    let background, text;
    if (volatilityPercentage < 10) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7)";
      text = "Very Low Volatility";
    } else if (volatilityPercentage < 40) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7)";
      text = "Low Volatility";
    } else if (volatilityPercentage < 60) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7)";
      text = "Medium Volatility";
    } else if (volatilityPercentage < 90) {
      background =
        "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7, #ff4191)";
      text = "High Volatility";
    } else {
      background =
        "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7, #ff4191)";
      text = "Very High Volatility";
    }

    return { text, width: `${volatilityPercentage}%`, background };
  };

  useEffect(() => {
    const newVolatilities = {};
    for (const coinSymbol in historicalData) {
      const volatilityResult = calculateVolatility(historicalData[coinSymbol]);
      newVolatilities[coinSymbol] = volatilityResult;
    }
    setVolatilities(newVolatilities);
  }, [historicalData]);

  const slides = [
    {
      h1: (
        <>
          Sign Up & Receive <br />
          100% Welcome Bonus!
        </>
      ),
      p: (
        <>
          Bet up, bet down, <span>trade x5,000</span> faster
          <br />
          and <span> win x50</span> your stake!
        </>
      ),
      image: "/images/bullzimage/newHomeBull.png",
      // mobileImage: "/images/bullzimage/bullzmobile.png",
    },
    // {
    //   h1: (
    //     <>
    //       Join Now And GGetET <br />
    //       <span>Exclusive Rewards!</span>
    //     </>
    //   ),
    //   p: (
    //     <>
    //       Dive Into Our Wide Range Of Casino Games And Enjoy Nonstop Excitement
    //       <br />
    //       <span> Exclusive offers available now</span>
    //     </>
    //   ),
    //   image: "/images/bullzimage/newHomeBull.png",
    //   // mobileImage: "/images/bullzimage/bullzmobile2.png",
    // },
    // {
    //   h1: (
    //     <>
    //       LEVERAGE, AND MAKE <br />
    //       <span>10X YOUR STAKE!</span>
    //     </>
    //   ),
    //   p: (
    //     <>
    //       Maximize your gains with our leverage feature
    //       <br />
    //       <span> Multiply your stake by 10</span>
    //     </>
    //   ),
    //   image: "/images/bullzimage/newHomeBull.png",
    //   // mobileImage: "/images/bullzimage/bullzmobile3.png",
    // },
  ];

  const extendedSlides = [...slides, slides[0]];

  // Effet pour changer de slide toutes les 3 secondes
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => prevIndex + 1);
  //     setIsTransitioning(true);
  //   }, 5000); // 3000ms = 3 secondes

  //   return () => clearInterval(interval); // Nettoyage de l'intervalle
  // }, []);

  // Gestion de la transition pour réinitialiser l'index après le dernier slide cloné
  // useEffect(() => {
  //   if (currentIndex === extendedSlides.length - 1) {
  //     // Après la fin de la transition vers le slide cloné, réinitialiser sans transition
  //     const timeout = setTimeout(() => {
  //       setIsTransitioning(false);
  //       setCurrentIndex(0);
  //     }, 500); // Correspond à la durée de la transition CSS

  //     return () => clearTimeout(timeout);
  //   } else {
  //     setIsTransitioning(true);
  //   }
  // }, [currentIndex, extendedSlides.length]);

  // Revenir à la transition normale après la réinitialisation
  // useEffect(() => {
  //   if (!isTransitioning) {
  //     // Forcer le reflow pour appliquer les changements sans transition
  //     void slidesContainerRef.current.offsetWidth;
  //     setIsTransitioning(true);
  //   }
  // }, [isTransitioning]);

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="App">
      {/* Navbar */}
      <Navbar />

      {/* Section 1: Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <div
            className="slides-container"
            ref={slidesContainerRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: isTransitioning
                ? "transform 0.5s ease-in-out"
                : "none",
              display: "flex",
              width: "100%",
            }}
          >
            <div className="slide">
              <div className="slide-text">
                <h1>{slides[0].h1}</h1>
                <p className="hero-text">{slides[0].p}</p>
                <div className="cta-btn-container">
                  <button
                    className="cta-btn"
                    onClick={() =>
                      (window.location.href = "https://app.bullz.games")
                    }
                  >
                    START PLAYING!
                  </button>
                  <div className="cta-icons">
                    <img
                      src="/images/bullzimage/whitetelegram.png"
                      alt="Telegram Icon"
                      className="icon"
                    />
                    <img
                      src="/images/bullzimage/metamask.png"
                      alt="Metamask Icon"
                      className="icon"
                    />
                    <img
                      src="/images/bullzimage/google.png"
                      alt="Google Icon"
                      className="icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-content-deux">
          <h1>
            How it works - <span>Quick, Easy, Cashable</span>
          </h1>
          <div className="boxes">
            {[
              {
                title: "1. \u00A0Pick your asset",
                description: (
                  <>
                    Choose a hot <br /> crypto to bet on
                  </>
                ),
                image: "/images/bullzimage/coins1.png",
              },
              {
                title: "2. \u00A0Place your bet",
                description: (
                  <>
                    Set the amount <br /> you want to bet
                  </>
                ),
                image: "/images/bullzimage/target1.png",
              },
              {
                title: "3. \u00A0Set the multiplier",
                description: (
                  <>
                    Trade faster <br /> up to x5000!
                  </>
                ),
                image: "/images/bullzimage/multiplier.png",
              },
              {
                title: "4. \u00A0Bust and Target Price",
                description: (
                  <>
                    Hit target, avoid bust, <br />
                    and win big!
                  </>
                ),
                image: "/images/bullzimage/target.png",
              },
              {
                title: "5. \u00A0Cash-out Anytime",
                description: (
                  <>
                    Cash-out in <br /> one click
                  </>
                ),
                image: "/images/bullzimage/cashout1.png",
              },
            ].map((item, index) => (
              <div className="box" key={index}>
                <h3>{item.title}</h3>
                <div className="box-content">
                  <p className="description">{item.description}</p>
                  <img src={item.image} alt="Icon" className="mini-image" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="video-placeholder">
          <div className="device-image">
            {/* Intégration de la vignette et de la vidéo */}
            {!isPlaying && (
              <div className="image-overlay" onClick={handlePlay}>
                <img
                  src="/images/bullzimage/5kfaster.png"
                  alt="vignette"
                  className="video-thumbnail"
                />
                {/* <div className="play-icon">&#9654;</div> */}
                <div className="play-icon">
                  <FaPlay />
                </div>
              </div>
            )}
            <video
              ref={videoRef}
              src="/videos/bullz-games-final.mp4"
              controls
              playsInline
              className={`gameplay-video ${!isPlaying ? "hidden" : ""}`}
            />
          </div>
        </div>
      </section>

      <section className="description-boxes-last-container-advantage">
        <div className="section-content-container">
          <div className="section-content-container-title">
            <h1>Latests Crypto</h1>
            <span>
              Explore the newest cryptocurrencies added to the market. Bet on
              the latest trends and enjoy the excitement of trading.
            </span>
            <img src="/images/bullzimage/screen1.png" alt="" />
          </div>
          <div className="section-content-container-title">
            <h1>Start With $1</h1>
            <span>
              Begin your journey with just $1. Feel the excitement and trade
              5000x faster, pushing your limits and diving into the action!
            </span>
            <img src="/images/bullzimage/screen2.png" alt="" />
          </div>
          <div className="section-content-container-title">
            <h1>Win Up To 50x!</h1>
            <span>
              Start small and dream big. Multiply your stakes by up to 50 times!
              Take bold risks, embrace the adventure, and enjoy every moment.
            </span>
            {/* <h1>Bonus & Rewards</h1>
            <span>
              Earn exclusive bonuses and rewards as you play. Unlock exciting
              milestones, achieve new heights, and enjoy every moment.
            </span> */}
            <img src="/images/bullzimage/screen-3.png" alt="" />
          </div>
        </div>
      </section>

      {/* <section className="assets-bets">
        <div className="numbers">
          <h2 className="numbers-title">Casino Stats</h2>
          <div className="stats-container">
            <div className="stat-box">
              <span className="stat-value">2,354</span>
              <span className="stat-label">Active Players</span>
            </div>
            <div className="stat-box">
              <span className="stat-value">14,837</span>
              <span className="stat-label">Registered Players</span>
            </div>
            <div className="stat-box">
              <span className="stat-value">$88,020</span>
              <span className="stat-label">Biggest Win</span>
            </div>
            <div className="stat-box">
              <span className="stat-value">268,412</span>
              <span className="stat-label">Total Bets</span>
            </div>
          </div>
        </div>

        <div className="latest-bets">
          <h2>Latest Bets</h2>
          <div className="latest-bets-scrollable">
            <AnimatePresence>
              {latestBets.map((bet, index) => (
                <motion.div
                  key={bet._id}
                  {...getAnimationProps()} // Ajoute les animations
                  className={`bet-row ${
                    index % 2 === 0 ? "bg-dark" : "bg-darker"
                  }`}
                >
                  <div className="bet-player">
                    <img
                      src={`https://iframe.bullz.games/images/avatar/${bet.avatar}`}
                      alt={bet.avatar}
                      className="user-image"
                    />
                    <div className="bet-player-info">
                      <span className="bet-user-name">{bet.username}</span>
                      <span className="bet-amount">$ {bet.amount}</span>
                    </div>
                  </div>

                  <div className="bet-pnl">
                    <span className="bet-pnl-label">P&L :</span>
                    <span
                      className={`bet-pnl-value ${
                        bet.profitOrLoss < 0 ? "negative" : ""
                      }`}
                    >
                      $ {bet.profitOrLoss}
                    </span>
                  </div>

                  <div className="bet-asset">
                    <div className="asset-icon">
                      <img
                        src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`}
                        alt={bet.cryptoId}
                      />
                      <span
                        className={`trend-arrow ${
                          bet.direction === "up" ? "up" : "down"
                        }`}
                      >
                        {bet.direction === "up" ? (
                          <FaAngleDoubleUp />
                        ) : (
                          <FaAngleDoubleDown />
                        )}
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </section> */}
      <section className="assets-bets">
        <div className="assets">
          <h2>Assets Available</h2>
          <div className="assets-grid">
            {cryptoList
              .filter((crypto) => crypto.price !== null)
              .map((crypto) => {
                const coinSymbol = crypto.cryptoName;
                const vol = volatilities[coinSymbol] || {
                  text: "Very Low Volatility",
                  width: "0%",
                  background: "#1a1d29",
                };

                return crypto.active ? (
                  <div className="asset-box" key={crypto.id}>
                    <div className="asset-header">
                      <img
                        src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`}
                        alt={crypto.id}
                        className="asset-logo"
                      />
                      <div className="asset-info">
                        {/* Truncated Asset Name with Tooltip */}
                        <span className="asset-name" title={crypto.cryptoName}>
                          {crypto.cryptoName.slice(0, 3).toUpperCase()}
                        </span>
                        <div className="asset-value-row">
                          {getFormattedValue(crypto.price, crypto.cryptoName)}
                          {/* Uncomment and modify if you want to display change percentage */}
                          {/* <span
                          className={`asset-change ${
                            key.change.startsWith("-") ? "negative" : ""
                          }`}
                        >
                          +1.2%
                        </span> */}
                        </div>
                      </div>
                    </div>
                    <span className="asset-volatility">{vol.text}</span>
                    <div className="progress-bar-container">
                      <div
                        className={`progress-bar ${
                          parseInt(vol.width) < 100 ? "progress-incomplete" : ""
                        }`}
                        style={{
                          width: vol.width,
                          background: vol.background,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </section>
      <section className="description-boxes-rewards">
        <div className="section-centent-rewards">
          <div className="slide">
            <div className="slide-text">
              <h1>
                Electrifying Rewards,
                <br className="responsive-break" /> Bonuses and Surprises
              </h1>
              <p className="hero-text-bottom">
                Boost your winnings, claim epic <span>rewards</span>,<br /> and
                enjoy exclusive perks every step of the way!
              </p>
              <div className="cta-btn-container">
                <button className="cta-btn">
                  <a
                    href="https://app.bullz.games"
                    style={{ textDecoration: "none" }}
                    className="a-btn"
                  >
                    START PLAYING!
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="description-boxes-last-container">
        <div className="section-content-player">
          <h2> Players Levels - Power Up Your Profile </h2>
          <div className="section-content-player-top">
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 1</h3>
              </div>
              <div className="section-content-container-title-players-top bg1">
                {/* <img src="/images/bullzimage/souris-final.png" alt="" /> */}
              </div>
              <div className="section-content-container-title-players-bottom">
                <span>The Mouse</span>
                <span>
                  Tiny but nimble. Take your first steps into the game and
                  explore the basics. This is where your journey begins with
                  small but steady moves.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 2</h3>
              </div>
              <div className="section-content-container-title-players-top bg2"></div>
              <div className="section-content-container-title-players-bottom">
                <span>The Bunny</span>
                <span>
                  Cautious but quick. Dip your toes before you go big, with
                  added precision. Perfect for learning how to play smart and
                  make calculated bets.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 3</h3>
              </div>
              <div className="section-content-container-title-players-top bg3"></div>
              <div className="section-content-container-title-players-bottom">
                <span>The Fox</span>
                <span>
                  Smart moves only – start playing the game like a true pro with
                  advanced strategic insights. Gain access to powerful tools
                  that make every decision truly count.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content-player-bottom">
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 4</h3>
              </div>
              <div className="section-content-container-title-players-top bg4"></div>
              <div className="section-content-container-title-players-bottom">
                <span>The Wolf</span>
                <span>
                  Time to go for it! Big bets, calculated risks, and exclusive
                  rewards await you. Take full control of the game with
                  confidence and strategy.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 5</h3>
              </div>
              <div className="section-content-container-title-players-top bg5"></div>
              <div className="section-content-container-title-players-bottom">
                <span>The Eagle</span>
                <span>
                  Bird's eye view of the market. You've got the moves and now
                  the big rewards. Reach for new heights with powerful tools and
                  insights.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content-container-title-players">
              <div className="section-content-container-title-players-bottom-vignette">
                <h3>Level 6</h3>
              </div>
              <div className="section-content-container-title-players-top bg6"></div>
              <div className="section-content-container-title-players-bottom">
                <span>The Bull</span>
                <span>
                  Strength and swagger. For the pros who own the game with
                  unmatched benefits. Lead the pack with elite privileges and
                  exclusive rewards.
                </span>
                <div className="section-content-container-title-players-bottom-container-general">
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Trading Volume</span>
                    <span>$3,000,000</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Reward</span>
                    <span>10 / Month</span>
                  </div>
                  <div className="section-content-container-title-players-bottom-container">
                    <span>Profit Share</span>
                    <span>3%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <div className="cta-content">
          <div className="slide">
            <div className="slide-text">
              <h1>
                Join The <span style={{ color: "#29f4a3" }}>Community</span>{" "}
                <br />
                Rule The Game!
              </h1>
              <p className="hero-text-bottom">
                Connect, compete, and thrive <br />
                in <span>the ultimate gaming space!</span>
              </p>
              <div className="cta-btn-container">
                <button className="cta-btn-telegram">
                  <a href="https://app.bullz.games" className="cta-link">
                    JOIN
                    <img
                      src="/images/bullzimage/telegram.png"
                      alt="Telegram"
                      className="cta-icon"
                    />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <img
          src="/images/bullzimage/familypicture.png"
          alt="Bullz"
          className="cta-image"
        /> */}
      </section>

    <Footer />
    </div>
  );
};

export default Homepage;
