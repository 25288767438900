import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      {/* Copyright */}
      <div className="footer-texts">
        <p>©2024 XENOM GROUP. ALL RIGHTS RESERVED.</p>
      </div>

      {/* Navigation Links */}
      <div className="footer-nav">
        <Link to="/terms-conditions">
          <span>Terms and Conditions</span>
        </Link>
        <a
          href="https://bullz.games/white-label"
          target="_blank"
          rel="noopener noreferrer"
        >
          Game API
        </a>
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        <a
          href="https://www.justraffic.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Affiliate Program
        </a>
        <Link to="/amlpolicy">
          <span>AML Policy</span>
        </Link>
        <Link to="/responsiblegaming">
          <span>Responsible Gaming</span>
        </Link>
      </div>

      {/* Company Information */}
      <div className="footer-texts">
        <p>
          XENOM GROUP is a Limited (LTD) company registered under the
          registration number 15739 in Anjouan Island. Our registration code is
          2024-06-10, and our tax residence is also in Anjouan Island with the
          tax number 15739. Our headquarters are located at Hamchako, Mutsamudu.
        </p>

        <p>
          XENOM GROUP is committed to upholding the highest standards of fair
          play and player protection. We operate under strict compliance with
          the gaming regulations of our licensed jurisdictions. Our priority is
          to provide a safe, transparent, and responsible gaming environment.
          Players are encouraged to gamble responsibly and to be aware of the
          risks associated with online gaming.
          <br />
          <br />
          Disclaimer: Crypto trading on Bullz.games is falling under the scope
          of our license. Unauthorized use of the website or its content is
          strictly prohibited.
        </p>
      </div>

      {/* Separator */}
      <hr className="footer-separator" />

      {/* Payment Icons */}
      <div className="payment-icons">
        <img src="images/bullzimage/18.png" alt="18+" />
        {/* <img src="images/bullzimage/gcb.svg" alt="Gaming Control Board" /> */}
        <img src="images/bullzimage/visa.png" alt="Visa" />
        <img src="images/bullzimage/mastercard.png" alt="MasterCard" />
        <img src="images/bullzimage/btc.png" alt="btc" />
        <img src="images/bullzimage/upi.svg" alt="upi" />
      </div>
    </footer>
  );
};

export default Footer;
