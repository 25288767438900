import React from "react";
import "./ResponsibleGaming.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
const ResponsibleGaming = () => {
  return (
    <div>
      <Navbar />
      <div className="terms-container">
        <h1>Responsible Gaming</h1>
        <p>
          <strong>Last updated:</strong> January 10th, 2025
        </p>

        <h2>Introduction</h2>
        <p>
          Bullz.games is a brand operated by <strong>XENOM GROUP</strong>, a
          Limited Company registered under the laws of Anjouan with registration
          number 15739 and headquarters located at Hamchako, Mutsamudu, Anjouan.
          References in this policy to "the Company," "We," or "Our" mean XENOM
          GROUP.
        </p>

        <h2>Responsible Gambling and Self-Exclusion</h2>

        <h3>Responsible Gambling</h3>
        <p>
          Gambling is intended as entertainment, providing fun and excitement
          for most users. However, we acknowledge that gambling may have negative
          side effects for some individuals. Recognized as a serious condition
          in medical science, pathological gambling is something we actively
          strive to address.
        </p>
        <p>
          We implement multiple measures under "Responsible Gambling" to minimize
          potential harms and assist users in managing their gambling behavior. Our
          focus includes education and promoting self-control among our users.
        </p>

        <h3>Information and Contact</h3>
        <p>
          Our Support team is available to assist you via email at any time:
        </p>
        <ul>
          <li>Email: <a href="mailto:contact@bullz.games">contact@bullz.games</a></li>
        </ul>
        <p>
          For self-assessment or additional resources on gambling addiction, you
          can visit:
        </p>
        <ul>
          <li>
            <a href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/">
              Self-assessment
            </a>
          </li>
          <li>
            <a href="https://www.begambleaware.org/safer-gambling/">
              Safer Gambling Resources
            </a>
          </li>
        </ul>

        <h3>Helpful Hints for Responsible Gambling</h3>
        <p>
          Consider the following guidelines to ensure gambling remains a fun
          and balanced activity:
        </p>
        <ul>
          <li>
            <strong>Set deposit limits:</strong> Determine how much you can afford
            to gamble based on your financial situation.
          </li>
          <li>
            <strong>Avoid chasing losses:</strong> Do not risk excessive amounts to
            recover previous losses.
          </li>
          <li>
            <strong>Set time limits:</strong> Ensure gambling does not dominate your
            other hobbies or responsibilities.
          </li>
          <li>
            <strong>Play smart:</strong> Avoid gambling when stressed, depressed, or
            under the influence of drugs, alcohol, or medication.
          </li>
          <li>
            <strong>Take breaks:</strong> Pause when you feel tired or cannot
            concentrate.
          </li>
          <li>
            <strong>Use one account:</strong> Keep a clear overview of your gambling
            activities by using a single account.
          </li>
        </ul>

        <h3>Minor Protection</h3>
        <p>
          You must be 18 years or older to use our services. Protect minors in
          your household by securing your login information and using internet
          filters such as:
          <a href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html">
            Wondershare FamiSafe
          </a>.
        </p>

        <h3>Self-Exclusion</h3>
        <p>
          If you need to take a break from gambling, our self-exclusion program
          allows you to exclude yourself for a set period (6 months to 5 years).
          During this time, creating a new account is strictly prohibited. To
          initiate self-exclusion, contact our support team:
        </p>
        <ul>
          <li>Email: <a href="mailto:contact@bullz.games">contact@bullz.games</a></li>
        </ul>
        <p>
          Self-exclusion is permanent for the specified duration and cannot be
          reversed for your protection.
        </p>

        <h2>Contact Us</h2>
        <p>
          For any questions about responsible gambling, contact us at
          <a href="mailto:contact@bullz.games">contact@bullz.games</a>.
        </p>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ResponsibleGaming;
