import React from "react";
import "./AMLPolicy.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
const AMLPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="terms-container">
        <h1>AML Policy</h1>
        <p>
          <strong>Last updated:</strong> 10 January 2025
        </p>

        <h2>Introduction</h2>
        <p>
          Bullz.games is a brand operated by <strong>XENOM GROUP</strong>, a
          Limited Company registered under the laws of Anjouan with registration
          number 15739 and headquarters located at Hamchako, Mutsamudu, Anjouan.
          References in this policy to "the Company," "We," or "Our" mean XENOM
          GROUP.
        </p>

        <h2>Objective of the AML Policy</h2>
        <p>
          We are committed to providing the highest level of security to our
          users. Our measures are designed to prevent money laundering and to
          comply with EU and other international anti-money laundering (AML)
          guidelines. These measures ensure the integrity of our platform and
          protect against illicit activities.
        </p>

        <h2>Definition of Money Laundering</h2>
        <ul>
          <li>
            The conversion or transfer of property, including money, to conceal
            its illegal origin or to assist others in evading legal
            consequences.
          </li>
          <li>
            The concealment of the true nature, source, or ownership of assets
            derived from criminal activities.
          </li>
          <li>
            The acquisition, possession, or use of assets, knowing they
            originate from criminal activity.
          </li>
          <li>
            Participation in or aiding activities related to money laundering.
          </li>
        </ul>

        <h2>Organization of the AML</h2>
        <p>
          The Board of Directors of XENOM GROUP oversees and enforces measures
          to prevent money laundering on Bullz.games. All employees and
          management are required to adhere to these measures.
        </p>

        <h2>AML Policy Changes</h2>
        <p>
          Any major change to the AML Policy requires approval from the managing
          director of XENOM GROUP. Updates will be communicated to all relevant
          stakeholders.
        </p>

        <h2>Know Your Customer (KYC) Policy</h2>
        <p>
          To maintain a secure and compliant environment, Bullz.games implements
          basic identity verification procedures for all users. These steps are
          designed to:
        </p>
        <ul>
          <li>Confirm the identity of users registering on our platform.</li>
          <li>Ensure compliance with applicable laws and regulations.</li>
          <li>Prevent unauthorized or fraudulent account activity.</li>
        </ul>
        <p>
          As part of the KYC process, users may be required to provide:
        </p>
        <ul>
          <li>A government-issued identification document (e.g., passport or ID card).</li>
          <li>Proof of address (e.g., utility bill or bank statement).</li>
          <li>Additional documents to verify the source of funds if necessary.</li>
        </ul>
        <p>
          Failure to provide the required information may result in restricted
          access to our services or account suspension.
        </p>

        <h2>Transaction Monitoring</h2>
        <p>
          We monitor transactions using a combination of automated systems and
          manual checks to detect unusual or suspicious activities. This
          includes:
        </p>
        <ul>
          <li>Identifying unusual transaction patterns or behaviors.</li>
          <li>
            Screening transactions for compliance with international sanctions
            and regulations.
          </li>
          <li>
            Utilizing software to detect suspicious deposit or withdrawal
            patterns.
          </li>
        </ul>

        <h2>Education and Training</h2>
        <p>
          Employees are periodically trained on AML, anti-terrorist financing,
          and sanctions compliance to maintain the highest standards of
          awareness and adherence.
        </p>

        <h2>Reporting</h2>
        <p>
          Suspicious activities are reported to the appropriate regulatory
          authorities. Customers identified as being involved in criminal
          activities will have their accounts suspended, and relevant
          information will be shared with the authorities.
        </p>

        <h2>Contact Us</h2>
        <p>
          For any questions about our AML Policy, please contact us at
          <a href="mailto:contact@bullz.games">contact@bullz.games</a>.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AMLPolicy;
