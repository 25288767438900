import React from "react";
import "./TermAndConditions.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const TermAndConditions = () => {
  return (
    <div>
      <Navbar />
      <div className="terms-container">
        <h1>Terms and Conditions of Service</h1>
        <p>
          <strong>Last updated:</strong> 15 January 2025
        </p>

        {/* COMPANY INFORMATION */}
        <h2>1. Company Information</h2>
        <p>
          <strong>XENOM GROUP</strong> is a Limited (LTD) company registered
          under the registration number <strong>15739</strong> in Anjouan
          Island. Our registration code is <strong>2024-06-10</strong>, and our
          tax residence is also in Anjouan Island with the tax number{" "}
          <strong>15739</strong>. Our headquarters are located at{" "}
          <strong>Hamchako, Mutsamudu</strong>.
        </p>
        <p>
          <strong>XENOM GROUP</strong> is committed to upholding the highest
          standards of fair play and player protection. We operate under strict
          compliance with the gaming regulations of our licensed jurisdictions.
          Our priority is to provide a safe, transparent, and responsible gaming
          environment. Players are encouraged to gamble responsibly and be aware
          of the risks associated with online gaming.
        </p>
        <p>
          <em>
            Disclaimer: Crypto trading on Bullz.games is falling under the scope
            of our license. Unauthorized use of the website or its content is
            strictly prohibited.
          </em>
        </p>

        {/* INTRODUCTION */}
        <h2>2. Introduction</h2>
        <p>
          These Terms and Conditions (<strong>"Terms"</strong> or{" "}
          <strong>"Agreement"</strong>) govern your access to and use of the
          services provided by <strong>Bullz.games</strong> (the{" "}
          <strong>"Website"</strong>). By accessing or using any part of this
          Website or its associated services (<strong>"Services"</strong>), you
          agree to be bound by these Terms. If you do not agree with any part of
          these Terms, you must immediately discontinue your use of the Website
          and Services.
        </p>

        {/* ACCEPTANCE OF TERMS */}
        <h2>3. Acceptance of Terms & Related Policies</h2>
        <p>
          By creating an account and using the Services, you expressly confirm
          that you have read and accepted:
        </p>
        <ul>
          <li>These Terms &amp; Conditions;</li>
          <li>Our Privacy Policy;</li>
          <li>Our Cookies Policy;</li>
          <li>
            Any specific game rules applicable to the Services, including
            betting limits and maximum winnings;
          </li>
          <li>
            Additional terms or guidelines related to promotions, bonuses,
            referral programs, tournaments, or other features;
          </li>
          <li>Our Affiliate Program terms (if applicable);</li>
          <li>Our AML (Anti-Money Laundering) Policy;</li>
          <li>Responsible Gaming guidelines.</li>
        </ul>
        <p>
          You are responsible for regularly reviewing these documents as we may
          periodically update them. Continued use of the Services following any
          update constitutes acceptance of the updated Terms.
        </p>

        {/* ELIGIBILITY */}
        <h2>4. Eligibility</h2>
        <p>By using our Services, you represent and warrant that:</p>
        <ul>
          <li>
            You are at least 18 years old (or the legal gambling age in your
            jurisdiction).
          </li>
          <li>
            You have the full legal capacity to enter into binding contracts.
          </li>
          <li>
            You are not prohibited from participating in online gambling
            activities in your jurisdiction.
          </li>
          <li>
            You have not been previously suspended or prohibited from using our
            Services.
          </li>
        </ul>
        <p>
          We reserve the right to request proof of age and/or identity at any
          time to verify that minors are not using the Services.
        </p>

        {/* ACCOUNTS & VERIFICATION */}
        <h2>5. Accounts and Verification</h2>
        <p>When creating an account on Bullz.games, you agree to:</p>
        <ul>
          <li>Provide accurate, current, and complete personal information.</li>
          <li>Maintain and promptly update your information as required.</li>
          <li>Use the account solely for personal, non-commercial use.</li>
          <li>Keep your login credentials confidential and secure.</li>
        </ul>
        <p>
          We reserve the right to limit accounts to one per individual,
          household, email address, IP address, or payment method. Violations of
          this policy may result in account suspension, closure, and/or
          forfeiture of funds.
        </p>
        <p>
          <strong>Identity Verification:</strong> You may be required to provide
          documents proving your identity, age, location, and/or source of
          funds. We may suspend or terminate your account if we are unable to
          complete our verification processes to our satisfaction.
        </p>

        {/* RESPONSIBLE GAMING */}
        <h2>6. Responsible Gaming</h2>
        <p>
          We are committed to promoting responsible gaming. If you feel that
          gaming interferes with your personal or financial well-being, please
          seek professional assistance. We may provide self-exclusion and other
          responsible gaming tools to assist you in managing your gambling
          activities.
        </p>

        {/* SUSPENSION & CLOSURE */}
        <h2>7. Suspension and Closure of Accounts</h2>
        <p>
          Bullz.games reserves the right to suspend or close your account, at
          our sole discretion, under circumstances that may include, but are not
          limited to:
        </p>
        <ul>
          <li>Suspected fraudulent or illegal activities.</li>
          <li>Breach of these Terms or violation of applicable laws.</li>
          <li>Incomplete or failed verification of identity or eligibility.</li>
          <li>Multiple accounts related to the same user or household.</li>
        </ul>
        <p>
          If your account is suspended or closed, any remaining balance may be
          withheld or confiscated in accordance with these Terms and applicable
          laws.
        </p>

        {/* RESTRICTED TERRITORIES */}
        <h2>8. Jurisdiction & Restricted Territories</h2>
        <p>
          You acknowledge that certain jurisdictions prohibit online gambling
          and gaming activities. By using our Services, you confirm that you are
          not located in a restricted territory. Attempts to bypass
          geo-restrictions using VPNs, proxy services, or other methods are
          strictly prohibited and may result in immediate account suspension.
        </p>
        <p>
          It is your responsibility to ensure that the laws of your jurisdiction
          do not prohibit your participation in our Services.
        </p>

        {/* DEPOSITS & WITHDRAWALS */}
        <h2>9. Deposits and Withdrawals</h2>
        <p>
          <strong>9.1 Deposits:</strong> All deposits must originate from
          payment methods legally and rightfully owned by you. Funds deposited
          are intended exclusively for gaming and betting activities. We reserve
          the right to reject any deposit at our sole discretion.
        </p>
        <p>
          <strong>9.2 Withdrawals:</strong> Withdrawals are subject to identity
          verification, risk assessments, and any other due diligence
          procedures. We reserve the right to place withdrawal limits, require
          additional documentation, or deny withdrawals if we detect
          irregularities.
        </p>
        <p>
          <strong>9.3 Fees and Charges:</strong> Some deposit or withdrawal
          methods may incur fees. We will make reasonable efforts to inform you
          of such fees on our Website. However, you are ultimately responsible
          for any fees imposed by your financial institution or payment service
          provider.
        </p>
        <p>
          <strong>9.4 Erroneous Credits:</strong> Any funds credited to your
          account in error must be reported to us immediately and returned upon
          request.
        </p>

        {/* GAME RULES & BETTING LIMITS */}
        <h2>10. Game Rules and Betting Limits</h2>
        <p>
          Each game or betting product may have specific rules, limits, and
          maximum winnings. You must read and understand these rules before
          participating. By placing a wager, you acknowledge that you fully
          understand the game mechanics, potential risks, and rewards.
        </p>
        <p>
          We reserve the right to modify betting limits, odds, and game rules at
          our sole discretion to ensure fair play and maintain the integrity of
          the Services.
        </p>

        {/* PROHIBITED ACTIVITIES */}
        <h2>11. Prohibited Activities</h2>
        <p>
          You are strictly prohibited from using the Website or Services for
          activities that are:
        </p>
        <ul>
          <li>Illegal, fraudulent, or malicious.</li>
          <li>In violation of these Terms or any applicable laws.</li>
          <li>
            Intended to manipulate or unfairly influence game outcomes, such as
            collusion, use of bots, or other automated software tools.
          </li>
          <li>
            Designed to circumvent geo-restrictions or responsible gaming
            measures.
          </li>
        </ul>
        <p>
          Any suspected violation of these prohibitions may result in immediate
          account suspension, forfeiture of funds, and/or legal action.
        </p>

        {/* BONUSES & PROMOTIONS */}
        <h2>12. Bonuses and Promotions</h2>
        <p>
          Bullz.games may offer bonuses, promotions, referral programs,
          tournaments, or other special offers from time to time. These offers (
          <strong>"Promotions"</strong>) are subject to additional terms and
          conditions, including but not limited to wagering requirements, time
          limits, and bonus expiration dates.
        </p>
        <p>
          Abuse of any Promotion, including attempting to exploit loopholes,
          engage in bonus arbitrage, or collude with other players, may result
          in the forfeiture of the bonus, associated winnings, and/or account
          suspension.
        </p>

        {/* INTELLECTUAL PROPERTY */}
        <h2>13. Intellectual Property</h2>
        <p>
          All trademarks, service marks, trade names, logos, domain names, and
          any other intellectual property rights in the Website and its content
          are the property of <strong>XENOM GROUP</strong> or its licensors. You
          may not copy, reproduce, redistribute, publish, reverse-engineer,
          decompile, or otherwise exploit any part of the Website without our
          express written consent.
        </p>

        {/* LIMITATION OF LIABILITY */}
        <h2>14. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, <strong>XENOM GROUP</strong>{" "}
          and its affiliates, officers, directors, agents, employees, or
          licensors shall not be liable for any direct, indirect, incidental,
          consequential, special, or punitive damages, including but not limited
          to loss of profits, data, or goodwill, arising out of or in connection
          with your access or use of the Website and Services.
        </p>
        <p>
          We make no warranties or representations, whether express or implied,
          regarding the accuracy or reliability of the content on our Website.
          Your use of the Website and participation in our Services is at your
          sole risk.
        </p>

        {/* INDEMNIFICATION */}
        <h2>15. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold harmless{" "}
          <strong>XENOM GROUP</strong> and its affiliates from and against any
          and all claims, liabilities, damages, losses, or expenses (including
          legal and accounting fees) arising out of or in any way connected with
          your breach of these Terms, your misuse of the Website or Services,
          and/or your violation of any applicable laws or regulations.
        </p>

        {/* DISPUTES */}
        <h2>16. Disputes and Governing Law</h2>
        <p>
          Any dispute arising out of or related to these Terms, the Website, or
          the Services shall first be addressed by contacting our support team
          at <a href="mailto:contact@bullz.games">contact@bullz.games</a>. We
          will make reasonable efforts to resolve disputes amicably.
        </p>
        <p>
          Unresolved disputes shall be governed by and construed in accordance
          with the laws of Anjouan Island, without regard to its conflict of law
          principles. You agree to submit to the exclusive jurisdiction of the
          courts located in Anjouan Island to resolve any disputes.
        </p>

        {/* TERMINATION */}
        <h2>17. Termination</h2>
        <p>
          We reserve the right to terminate or suspend your access to the
          Services at our sole discretion, without prior notice or liability, if
          you breach any of the provisions of these Terms. Upon termination, any
          rights or licenses granted to you under these Terms shall immediately
          cease.
        </p>

        {/* CHANGES TO TERMS */}
        <h2>18. Changes to These Terms</h2>
        <p>
          We may update or revise these Terms at any time. The updated Terms
          will be posted on the Website with a new “Last updated” date. It is
          your responsibility to review these Terms periodically. Your continued
          use of the Services after any changes indicates your acceptance of the
          revised Terms.
        </p>

        {/* SEVERABILITY */}
        <h2>19. Severability</h2>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable under any applicable law, such provision shall be
          modified or removed to the minimum extent necessary so that these
          Terms shall otherwise remain in full force and effect.
        </p>

        {/* ENTIRE AGREEMENT */}
        <h2>20. Entire Agreement</h2>
        <p>
          These Terms, together with any documents expressly referenced herein,
          constitute the entire agreement between you and{" "}
          <strong>XENOM GROUP</strong> regarding your use of the Website and the
          Services, superseding all prior or contemporaneous understandings,
          agreements, or communications.
        </p>

        {/* CONTACT US */}
        <h2>21. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms, please
          contact us at{" "}
          <a href="mailto:contact@bullz.games">contact@bullz.games</a>.
        </p>

        <hr />
        <p style={{ textAlign: "center" }}>
          ©2024 <strong>XENOM GROUP</strong>. ALL RIGHTS RESERVED.
        </p>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TermAndConditions;
