import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermAndConditions from "./pages/TermAndConditions/TermAndConditions";
import AMLPolicy from "./pages/AMLPolicy/AMLPolicy";
import ResponsibleGaming from "./pages/ResponsibleGaming/ResponsibleGaming";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermAndConditions />} />
        <Route path="/amlpolicy" element={<AMLPolicy />} />
        <Route path="/responsiblegaming" element={<ResponsibleGaming />} />
      </Routes>
    </div>
  );
}

export default App;
